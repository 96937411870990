<template
  ><v-form>
    <v-layout>
      <v-app-bar app height="124" class="align-start elevation-1">
        <v-col cols="12" class="py-0">
          <v-row dense class="align-center flex-nowrap">
            <v-col cols="auto" class="d-flex d-lg-none pa-0">
              <!--Platzhalter für mobile Menu-->
              <div style="width: 50px"></div>
            </v-col>
            <v-col cols="auto">
              <v-toolbar-title>Hersteller bearbeiten</v-toolbar-title>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="auto">
              <v-btn outlined color="red lighten-1" @click="$router.go(-1)">
                <v-icon>mdi-cancel</v-icon>
                <span class="ml-1 d-none d-lg-flex">Abbrechen</span>
              </v-btn>
            </v-col>
            <v-col cols="auto">
              <v-btn color="green lighten-1 grey--text text--lighten-4" @click="herstellerSpeichern()" :disabled="validSafe">
                <v-icon>mdi-check</v-icon>
                <span class="ml-1 d-none d-lg-flex">Speichern</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-app-bar>

      <v-container fluid class="px-0 pt-0">
        <v-col cols="11" xl="8" lg="9" md="11" class="mx-auto px-0 pt-0">
          <v-row dense class="align-center justify-center pb-5">
            <v-card tile outlined class="flex-grow-1">
              <v-col cols="12">
                <v-card-title class="px-2">
                  <v-row dense>
                    <v-col cols="12">
                      <v-toolbar-title>
                        Hauptdaten
                      </v-toolbar-title>
                    </v-col>
                  </v-row>
                </v-card-title>
                <v-divider inset></v-divider>
                <v-card-text class="px-2 subtitle-2">
                  <v-row dense align="center">
                    <v-col cols="12" xl="4" lg="4" md="5" sm="6">
                      <v-checkbox v-model="editableDataset.ist_aktiv" :true-value="1" :false-value="0"
                        ><template v-slot:label>
                          <span class="text--primary subtitle-2">
                            Aktiver Hersteller?
                          </span>
                        </template>
                      </v-checkbox>
                    </v-col>
                    <v-spacer></v-spacer>
                  </v-row>
                  <v-row dense align="start">
                    <v-col cols="12" xl="6" lg="8" md="10" sm="12">
                      <v-row dense>
                        <v-col cols="12" xl="10" lg="10" md="8" sm="8">
                          <span class="font-weight-bold">Name</span>

                          <v-text-field v-model="editableDataset.Name" hide-details dense outlined required placeholder="Name"> </v-text-field>
                        </v-col>
                      </v-row>
                      <v-row dense>
                        <v-col cols="12" xl="6" lg="6" md="6" sm="6">
                          <span class="font-weight-bold">Abkürzung</span>

                          <v-text-field v-model="editableDataset.Abkürzung" hide-details dense outlined required placeholder="Abkürzung">
                          </v-text-field>
                        </v-col>
                      </v-row>
                      <v-row dense>
                        <v-col cols="12" xl="10" lg="10" md="8" sm="8">
                          <span class="font-weight-bold">Kundennummer</span>

                          <v-text-field v-model="editableDataset.Kundennummer" hide-details dense outlined required placeholder="Kundennummer">
                          </v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" xl="6" lg="8" md="10" sm="12">
                      <v-row>
                        <v-col cols="12">
                          <span class="font-weight-bold">Sortiment</span>
                          <v-textarea hide-details dense outlined clearable v-model="editableDataset.Sortiment" placeholder="Sortiment"> </v-textarea>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-spacer></v-spacer>
                  </v-row>
                </v-card-text>
              </v-col>
            </v-card>
          </v-row>
          <v-row dense class="align-center justify-center pb-5" v-show="aktiverHersteller">
            <v-card tile outlined class="flex-grow-1">
              <v-col cols="12">
                <v-card-title class="px-2">
                  <v-row dense>
                    <v-col cols="12">
                      <v-toolbar-title>
                        Kontakt
                      </v-toolbar-title>
                    </v-col>
                  </v-row>
                </v-card-title>
                <v-divider inset></v-divider>
                <v-card-text class="px-2 subtitle-2">
                  <v-row dense align="center">
                    <v-col cols="12" xl="5" lg="6" md="5" sm="6">
                      <span class="font-weight-bold">Kontaktperson</span>
                      <v-text-field v-model="editableDataset.Kontaktperson" hide-details dense outlined required placeholder="Kontaktperson">
                      </v-text-field>
                    </v-col>
                    <v-spacer></v-spacer>
                  </v-row>
                  <v-row dense align="center">
                    <v-col cols="12" xl="5" lg="5" md="5" sm="6">
                      <span class="font-weight-bold">Telefon</span>
                      <v-text-field v-model="editableDataset.Telefon" hide-details dense outlined required placeholder="Telefon"> </v-text-field>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="12" xl="7" lg="7" md="7" sm="8">
                      <span class="font-weight-bold">Email</span>
                      <v-text-field v-model="editableDataset.Email" hide-details dense outlined required placeholder="Email"> </v-text-field>
                    </v-col>
                    <v-spacer></v-spacer>
                  </v-row>
                  <v-row dense align="center">
                    <v-col cols="12" xl="8" lg="8" md="8" sm="10">
                      <span class="font-weight-bold">IBAN</span>
                      <v-text-field v-model="editableDataset.IBAN" hide-details dense outlined required placeholder="IBAN"> </v-text-field>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="12" xl="4" lg="4" md="4" sm="6">
                      <span class="font-weight-bold">BIC</span>
                      <v-text-field v-model="editableDataset.BIC" hide-details dense outlined required placeholder="BIC"> </v-text-field>
                    </v-col>
                    <v-spacer></v-spacer>
                  </v-row>
                  <v-row dense align="center">
                    <v-col cols="12" xl="6" lg="8" md="6" sm="8">
                      <span class="font-weight-bold">Website</span>
                      <v-text-field v-model="editableDataset.Website" hide-details dense outlined required placeholder="www.website.de">
                      </v-text-field>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="12" xl="6" lg="8" md="6" sm="8">
                      <span class="font-weight-bold">Shop</span>
                      <v-text-field v-model="editableDataset.Shop" hide-details dense outlined required placeholder="www.shop.de"> </v-text-field>
                    </v-col>
                    <v-spacer></v-spacer>
                  </v-row>
                </v-card-text>
              </v-col>
            </v-card>
          </v-row>
          <v-row dense class="align-center justify-center pb-5" v-show="aktiverHersteller">
            <v-card tile outlined class="flex-grow-1">
              <v-col cols="12">
                <v-card-title class="px-2">
                  <v-row dense>
                    <v-col cols="12">
                      <v-toolbar-title>
                        Anschrift
                      </v-toolbar-title>
                    </v-col>
                  </v-row>
                </v-card-title>
                <v-divider inset></v-divider>
                <v-card-text class="px-2 subtitle-2">
                  <v-row dense align="center">
                    <v-col cols="12" xl="4" lg="5" md="6" sm="8">
                      <span class="font-weight-bold">Land</span>
                      <v-text-field v-model="editableDataset.Land" hide-details dense outlined required placeholder="Land"> </v-text-field>
                    </v-col>
                    <v-spacer></v-spacer>
                  </v-row>
                  <v-row dense align="center">
                    <v-col cols="9" xl="6" lg="6" md="8" sm="9">
                      <span class="font-weight-bold">Straße</span>
                      <v-text-field v-model="editableDataset.Straße" hide-details dense outlined required placeholder="Straße"> </v-text-field>
                    </v-col>
                    <v-col cols="3" xl="2" lg="2" md="2" sm="3">
                      <span class="font-weight-bold">Nr.</span>
                      <v-text-field v-model="editableDataset.Hausnummer" hide-details dense outlined required placeholder="Nr."> </v-text-field>
                    </v-col>
                    <v-spacer></v-spacer>
                  </v-row>
                  <v-row dense align="center">
                    <v-col cols="4" xl="3" lg="3" md="3" sm="4">
                      <span class="font-weight-bold">PLZ</span>
                      <v-text-field v-model="editableDataset.PLZ" hide-details dense outlined required placeholder="PLZ"> </v-text-field>
                    </v-col>
                    <v-col cols="8" xl="5" lg="6" md="8" sm="8">
                      <span class="font-weight-bold">Ort</span>
                      <v-text-field v-model="editableDataset.Ort" hide-details dense outlined required placeholder="Ort"> </v-text-field>
                    </v-col>
                    <v-spacer></v-spacer>
                  </v-row>
                </v-card-text>
              </v-col>
            </v-card>
          </v-row>
        </v-col>
      </v-container>
    </v-layout>

    <LeavePathWrapper ref="leavePath">
      <LeavePathDialog />
    </LeavePathWrapper>
  </v-form>
</template>

<script>
import LeavePathWrapper from "../../../../components/dialogs/LeavePathWrapper.vue";
import LeavePathDialog from "../../../../components/dialogs/LeavePathDialog.vue";

export default {
  components: {
    LeavePathWrapper,
    LeavePathDialog,
  },

  created() {
    this.initialize();
  },

  mounted() {
    this.initialize();
  },

  beforeRouteLeave(to, from, next) {
    if (this.saved === true || !this.checkDifferences) {
      next();
    } else if (this.saved === false) {
      this.$refs.leavePath.open().then((result) => {
        if (result === true) {
          next();
        } else if (result === false) {
          next(false);
        }
      });
    }
  },

  data: () => ({
    aktiverHersteller: true,
    defaultDataset: {
      Hersteller_ID: null,
      Abkürzung: null,
      Name: null,
      Kundennummer: null,
      Sortiment: null,
      Land: null,
      Straße: null,
      Hausnummer: null,
      PLZ: null,
      Ort: null,
      Kontaktperson: null,
      Telefon: null,
      Email: null,
      IBAN: null,
      BIC: null,
      Website: null,
      Shop: null,
      ist_aktiv: null,
    },
    editableDataset: {},
    initialDataset: {},
    saved: false,
  }),

  computed: {
    checkDifferences() {
      if (
        this.editableDataset.Hersteller_ID === this.initialDataset.Hersteller_ID &&
        this.editableDataset.Name === this.initialDataset.Name &&
        this.editableDataset.Abkürzung === this.initialDataset.Abkürzung &&
        this.editableDataset.Kundennummer === this.initialDataset.Kundennummer &&
        this.editableDataset.Kontaktperson === this.initialDataset.Kontaktperson &&
        this.editableDataset.Telefon === this.initialDataset.Telefon &&
        this.editableDataset.Email === this.initialDataset.Email &&
        this.editableDataset.Website === this.initialDataset.Website &&
        this.editableDataset.Shop === this.initialDataset.Shop &&
        this.editableDataset.IBAN === this.initialDataset.IBAN &&
        this.editableDataset.BIC === this.initialDataset.BIC &&
        this.editableDataset.Straße === this.initialDataset.Straße &&
        this.editableDataset.Hausnummer === this.initialDataset.Hausnummer &&
        this.editableDataset.PLZ === this.initialDataset.PLZ &&
        this.editableDataset.Ort === this.initialDataset.Ort &&
        this.editableDataset.Sortiment === this.initialDataset.Sortiment &&
        this.editableDataset.ist_aktiv === this.initialDataset.ist_aktiv
      ) {
        return false;
      } else return true;
    },

    validSafe() {
      if (!this.editableDataset.Name || !this.editableDataset.Abkürzung || !this.editableDataset.Sortiment) {
        return true;
      } else return false;
    },
  },

  methods: {
    async initialize() {
      const response = await fetch(`/api/database/manufacturers/dataset/edit/${this.$route.params.id}`);
      const json = await response.json();

      for (let [key, value] of Object.entries(json)) {
        this[key] = value;
      }

      this.initialDataset = Object.assign({}, this.editableDataset);
    },

    async herstellerSpeichern() {
      const datasetToUpdate = {};

      if (Object.keys(datasetToUpdate).length === 0) {
        Object.assign(datasetToUpdate, {
          Hersteller_ID: this.editableDataset.Hersteller_ID,
          Abkürzung: this.editableDataset.Abkürzung,
          Name: this.editableDataset.Name,
          Kundennummer: this.editableDataset.Kundennummer,
          Sortiment: this.editableDataset.Sortiment,
          Land: this.editableDataset.Land,
          Straße: this.editableDataset.Straße,
          Hausnummer: this.editableDataset.Hausnummer,
          PLZ: this.editableDataset.PLZ,
          Ort: this.editableDataset.Ort,
          Kontaktperson: this.editableDataset.Kontaktperson,
          Telefon: this.editableDataset.Telefon,
          Email: this.editableDataset.Email,
          IBAN: this.editableDataset.IBAN,
          BIC: this.editableDataset.BIC,
          Website: this.editableDataset.Website,
          Shop: this.editableDataset.Shop,
          ist_aktiv: this.editableDataset.ist_aktiv,
        });
      }

      const saveresponse = await fetch("/api/database/manufacturers/dataset/update", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(datasetToUpdate),
      });

      this.$root.actionSnackbar.show({
        dataset: "Hersteller",
        action: "bearbeitet",
      });
      this.saved = true;
      this.$router.go(-1);
    },
  },
};
</script>
